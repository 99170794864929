/**
 * Chart
 */
const chart = () => {
  const ctx1 = document.querySelector('.js-chart-doughnut1');
  const ctx2 = document.querySelector('.js-chart-doughnut2');
  const ctx3 = document.querySelector('.js-chart-doughnut3');

  if (ctx1 && ctx2 && ctx3) {
    const container = ctx1.closest('.js-anima');
    var donuthole = 57;
    if (screen.width < 1200) {
      donuthole = 50;
    }

    const chart1 =  {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [23.8, 16.2, 10.7, 10.2, 6.5, 32.6],
            backgroundColor: ['#00527D', '#0D72A8', '#1685BF', '#3399CF', '#57A8D2', '#76B9DC'],
            borderWidth: 0,
          }
        ]
      },
      options: {
        events: null,
        cutout: donuthole,
        animation: {
          duration: 2000,
        },
      },
    };

    const chart2 =  {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [10.2, 13.7, 9.5, 9.1, 7.8, 49.7],
            backgroundColor: ['#00527D', '#0D72A8', '#1685BF', '#3399CF', '#57A8D2', '#76B9DC'],
            borderWidth: 0,
          }
        ]
      },
      options: {
        events: null,
        cutout: donuthole,
        animation: {
          duration: 2000,
        },
      },
    };

    const chart3 =  {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [20.9, 16.1, 10.1, 9.1, 8.4, 35.4],
            backgroundColor: ['#00527D', '#0D72A8', '#1685BF', '#3399CF', '#57A8D2', '#76B9DC'],
            borderWidth: 0,
          }
        ]
      },
      options: {
        events: null,
        cutout: donuthole,
        animation: {
          duration: 2000,
        },
      },
    };
    // @ts-ignore
    let controller = new ScrollMagic.Controller();
     // @ts-ignore
    let scene = new ScrollMagic.Scene({
      triggerElement: container,
      reverse: false,
      triggerHook: 0.7,
    }).addTo(controller);
      const chart1Config = chart1;
      const chart2Config = chart2;
      const chart3Config = chart3;
    scene.on('enter', function () {
       // @ts-ignore
      new Chart(ctx1, chart1Config);
       // @ts-ignore
      new Chart(ctx2, chart2Config);
       // @ts-ignore
      new Chart(ctx3, chart3Config);
    });
  }
};

export default chart;