/**
 * Animation
 */
declare var ScrollMagic : any;

const anima = () => {
  const container = document.querySelectorAll('.js-anima');
  
  if (container) {
    container.forEach(cont=>{
      const elem = cont.querySelectorAll('.anima');

      if (elem) {
        elem.forEach(item=>{
          const delay = item.getAttribute('data-anima-delay');
          const duration = item.getAttribute('data-anima-duration');

          if (delay) {
            (item as HTMLElement).style.animationDelay = delay + 'ms';
          }

          if (duration) {
            (item as HTMLElement).style.animationDuration = duration + 'ms';
          }
        });

        let controller = new ScrollMagic.Controller();
        let scene = new ScrollMagic.Scene({
          triggerElement: cont,
          reverse: false,
          triggerHook: 0.7,
        }).addTo(controller);
          // .addIndicators({name: "animation-scene"})

        scene.on('enter', function () {
          elem.forEach(item=>{
            item.classList.add('animate');
          });
        });
      }
    });
  }
};

export default anima;