import header from './components/header';
import chart from './components/chart';
import splide from './components/splide';
import toggle from './components/toggle';
import anima from './components/anima';
import bar from './components/bar';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    header();
    chart();
    splide();
    toggle();
    anima();
    bar();
  },
  false
);
