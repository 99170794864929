/**
 * Header functionality
 */
const header = () => {
  const headerCtn = document.querySelector('.js-header');
  const toggle = document.querySelector('.js-toggle');
  const navLinks = document.querySelectorAll('.header__nav-link');

  if (toggle && headerCtn) {
    toggle?.addEventListener('click', () => {
      headerCtn?.classList.toggle('active');
      document.body.classList.toggle('overflow');
    });
  }

  if (headerCtn) {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 300) {
        headerCtn?.classList.add('fixed');
      } else {
        headerCtn?.classList.remove('fixed');
      }
    });
  }

  if (navLinks) {
    navLinks.forEach(navLink => {
      navLink.addEventListener('click', () => {
        headerCtn?.classList.remove('active');
      });
    });
  }

};

export default header;